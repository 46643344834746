$(document).on('turbolinks:load', function () {

    var locale = '<%= I18n.locale %>';

    var cc = initCookieConsent();

    cc.run({
        current_lang: locale,
        autoclear_cookies: true,                   // default: false
        theme_css: 'https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.7.1/dist/cookieconsent.css',  // 🚨 replace with a valid path
        page_scripts: true,                        // default: false

        // delay: 0,                               // default: 0
        auto_language: 'document',               // default: null; could also be 'browser' or 'document'
        // autorun: true,                          // default: true
        // force_consent: false,                   // default: false
        // hide_from_bots: false,                  // default: false
        // remove_cookie_tables: false             // default: false
        // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
        // cookie_expiration: 182,                 // default: 182 (days)
        // cookie_necessary_only_expiration: 182   // default: disabled
        // cookie_domain: location.hostname,       // default: current domain
        // cookie_path: '/',                       // default: root
        // cookie_same_site: 'Lax',                // default: 'Lax'
        // use_rfc_cookie: false,                  // default: false
        // revision: 0,                            // default: 0

        onFirstAction: function (user_preferences, cookie) {
            // callback triggered only once
        },

        onAccept: function (cookie) {

        },

        onChange: function (cookie, changed_preferences) {
        },

        languages: {
            'cs': {
                consent_modal: {
                    title: 'Používáme cookies!',
                    description: 'K provozování těchto webových stránek společnost Českomoravská Nemovitostní a.s. používá soubory cookies a podobné technologie v nezbytném rozsahu. Pro užití volitelných souborů cookies za účelem zlepšení a personalizace vaší zkušenosti shromažďování analytických údajů, jako je počet návštěv a zdrojů návštěvnosti, poskytování reklamy a komunikace s třetími stranami žádá společnost Českomoravská Nemovitostní a.s. váš souhlas <button type="button" data-cc="c-settings" class="cc-link">Upravit nastavení</button>',
                    primary_btn: {
                        text: 'Potvrdit vše',
                        role: 'accept_all'              // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Odmítnout vše',
                        role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: 'Předvolby souborů cookie',
                    save_settings_btn: 'Uložit nastavení',
                    accept_all_btn: 'Potvrdit vše',
                    reject_all_btn: 'Odmítnout vše',
                    close_btn_label: 'Zavřít',
                    cookie_table_headers: [
                        {col1: 'Název'},
                        {col2: 'Doména'},
                        {col3: 'Expirace'},
                        {col4: 'Popis'}
                    ],
                    blocks: [
                        {
                            title: 'Používání souborů cookie',
                            description: 'Soubory cookie používám k zajištění základních funkcí webových stránek a ke zlepšení vašeho online zážitku. U každé kategorie si můžete zvolit, zda se chcete přihlásit nebo odhlásit, kdykoli budete chtít. Další podrobnosti týkající se souborů cookie a dalších citlivých údajů naleznete v úplném znění zde: <a href="/documents/cmn-inforamce-o-cookies.pdf" target="_blank" class="cc-link">Zásady užívaní souboru cookies</a>.'
                        }, {
                            title: 'Nezbytné',
                            description: 'Tyto cookies jsou vyžadovány pro správnou funkčnost našich webových stránek a v našem systému je nelze vypnout.',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                            },
                            cookie_table: [             // list of all expected cookies
                                {
                                    col1: 'cc_cookie',       // match all cookies starting with "_ga"
                                    col2: '.cm-n.cz',
                                    col3: '6 měsíců',
                                    col4: 'Uchovává informace o souhlasu s používáním cookies',
                                    is_regex: true
                                }
                            ]
                        }, {
                            title: 'Výkonnostní a analytické',
                            description: 'Tyto soubory cookie umožňují webové stránce zapamatovat si volby, které jste provedli v minulosti.',
                            toggle: {
                                value: 'analytics',     // your cookie category
                                enabled: false,
                                readonly: false
                            },
                            cookie_table: [             // list of all expected cookies
                                {
                                    col1: '_ga',       // match all cookies starting with "_ga"
                                    col2: '.cm-n.cz',
                                    col3: '2 roky',
                                    col4: 'Tento název souboru cookie je spojen se službou Google Universal Analytics, což je významná aktualizace běžněji používané analytické služby společnosti Google. Tento soubor cookie se používá k rozlišení jedinečných uživatelů přiřazením náhodně vygenerovaného čísla jako identifikátoru klienta. Je obsažen v každém požadavku na stránku na webu a slouží k výpočtu údajů o návštěvnících, relacích a kampaních pro analytické přehledy webu.',
                                    is_regex: true
                                },
                                {
                                    col1: '_gid',
                                    col2: '.cm-n.cz',
                                    col3: '1 den',
                                    col4: 'Tento soubor cookie je nastaven službou Google Analytics. Ukládá a aktualizuje jedinečnou hodnotu pro každou navštívenou stránku a slouží k počítání a sledování zobrazení stránek.',
                                }
                            ]
                        }, {
                            title: 'Reklamní',
                            description: 'Tyto soubory cookie shromažďují informace o tom, jak webové stránky používáte, které stránky jste navštívili a na které odkazy jste klikli. Všechny údaje jsou anonymizované a nelze je použít k vaší identifikaci.',
                            toggle: {
                                value: 'targeting',
                                enabled: false,
                                readonly: false
                            },
                            cookie_table: [             // list of all expected cookies
                                {
                                    col1: 'leady_session_id',       // match all cookies starting with "_ga"
                                    col2: '.cm-n.cz',
                                    col3: 'po dobu návštěvy',
                                    col4: 'Cookie umožňuje rozpoznat firemní návštěvníky. Dokáže určit vaši společnost, nikoli Vás osobně. Pracuje výlučně s veřejnými daty.',
                                    is_regex: true
                                }
                            ]
                        }
                    ]
                }
            },
            'en': {
                consent_modal: {
                    title: 'We use cookies!',
                    description: 'Českomoravská Nemovitostní a.s. uses only cookies and similar technologies to the extent necessary for operation of this website. Českomoravská Nemovitostní a.s. asks for your consent to use optional cookies to improve and personalize your experience by collecting analytical data such as number of visits and traffic sources, providing advertising and communicating with third parties. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                    primary_btn: {
                        text: 'Accept all',
                        role: 'accept_all'              // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Reject all',
                        role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: 'Cookie preferences',
                    save_settings_btn: 'Save settings',
                    accept_all_btn: 'Accept all',
                    reject_all_btn: 'Reject all',
                    close_btn_label: 'Close',
                    cookie_table_headers: [
                        {col1: 'Name'},
                        {col2: 'Domain'},
                        {col3: 'Expiration'},
                        {col4: 'Description'}
                    ],
                    blocks: [
                        {
                            title: 'Cookie usage',
                            description: 'ČMN uses cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="/documents/cmn-informace-o-cookies-en.pdf" target="_blank" class="cc-link">Cookie Policy</a>.'
                        }, {
                            title: 'Strictly necessary cookies',
                            description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                            },
                            cookie_table: [             // list of all expected cookies
                                {
                                    col1: 'cc_cookie',       // match all cookies starting with "_ga"
                                    col2: '.cm-n.cz',
                                    col3: '6 months',
                                    col4: 'The cookie allows you to recognize corporate visitors.',
                                    is_regex: true
                                }
                            ]
                        }, {
                            title: 'Performance and Analytics cookies',
                            description: 'These cookies allow the website to remember the choices you have made in the past',
                            toggle: {
                                value: 'analytics',     // your cookie category
                                enabled: false,
                                readonly: false
                            },
                            cookie_table: [             // list of all expected cookies
                                {
                                    col1: '_ga',       // match all cookies starting with "_ga"
                                    col2: '.cm-n.cz.cz',
                                    col3: '2 years',
                                    col4: 'This cookie name is associated with Google Universal Analytics - which is a significant update to Google\'s more commonly used analytics service. This cookie is used to distinguish unique users by assigning a randomly generated number as a client identifier. It is included in each page request in a site and used to calculate visitor, session and campaign data for the sites analytics reports.',
                                    is_regex: true
                                },
                                {
                                    col1: '_gid',
                                    col2: '.cm-n.cz.cz',
                                    col3: '1 day',
                                    col4: '\tThis cookie is set by Google Analytics. It stores and update a unique value for each page visited and is used to count and track pageviews.',
                                }
                            ]
                        }, {
                            title: 'Advertisement and Targeting cookies',
                            description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                            toggle: {
                                value: 'targeting',
                                enabled: false,
                                readonly: false
                            },
                            cookie_table: [             // list of all expected cookies
                                {
                                    col1: 'leady_session_id',       // match all cookies starting with "_ga"
                                    col2: '.cm-n.cz',
                                    col3: 'For the duration of the visit',
                                    col4: 'The cookie allows you to recognize corporate visitors. It can identify your company, not you personally. It works exclusively with public data.',
                                    is_regex: true
                                }
                            ]
                        }
                    ]
                }
            }


        }
    });

});
